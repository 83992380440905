import React, { useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { Password } from "primereact/password";
import { Toast } from "primereact/toast";
import { InputMask, InputMaskChangeEvent } from "primereact/inputmask";
import { Calendar } from "primereact/calendar";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Button } from "primereact/button";
import {
  url_register,
  url_patient,
  url_patient_verify,
  url_patient_info,
  url_checkUser,
  url_checkPatient,
  url_updateEmail,
} from "../lib/url";
import "./Registration.css";
import { useSelector } from "react-redux";
import { RootState } from "../Redux/store";

const generi = [
  { name: "Maschio", code: true },
  { name: "Femmina", code: false },
  { name: "Altro", code: null },
];

const Registration: React.FC<{ setActiveIndex: (index: number) => void }> = ({ setActiveIndex }) => {
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [codiceFiscale, setCodiceFiscaleInput] = useState<string>("");
  const [nome, setNome] = useState<string>("");
  const [cognome, setCognome] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [telefono, setTelefono] = useState<string>("");
  const [birthdate, setBirthdate] = useState<Date | null>(null);
  const [genere, setGenere] = useState<boolean | null>(null);
  const [passwordError, setPasswordError] = useState<string>(""); // Stato per gestire gli errori della password
  const toast = useRef<Toast>(null);
  const token = useSelector((state: RootState) => state.user.token);

  const handleTelefonoChange = (e: InputMaskChangeEvent) => {
    setTelefono(e.value ? e.value.toString() : "");
  };

  const handleCodiceFiscaleChange = (e: InputMaskChangeEvent) => {
    setCodiceFiscaleInput(e.value ? e.value.toString() : "");
  };

  // Funzione di validazione della password
  const validatePassword = (password: string) => {
    const regex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    return regex.test(password);
  };

  // Validazione della password al "blur" (quando l'utente lascia il campo)
  const handlePasswordBlur = () => {
    if (!validatePassword(password)) {
      setPasswordError("La password deve essere lunga almeno 8 caratteri, contenere lettere, numeri e un simbolo speciale.");
    } else {
      setPasswordError(""); // Resetta l'errore se la password è corretta
    }
  };

  const postRegister = async (bodyReg: any, patientBody: any, codiceFiscale: string, verifyBody: any) => {
    try {
      // Verifica esistenza utente con CF ed email
      const checkUserResponse = await fetch(url_checkUser, {
        method: "POST",
        body: JSON.stringify({ codiceFiscale: verifyBody.fiscalCode, email: verifyBody.mail }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (checkUserResponse.ok) {
        const checkUserData = await checkUserResponse.json();
        if (checkUserData.exists) {
          toast.current?.show({
            severity: "warn",
            summary: "Utente già registrato",
            detail: "L'utente è già registrato. Effettua il login o recupera la password.",
            life: 10000,
          });
          return;
        }
      } else if (checkUserResponse.status === 400) {
        toast.current?.show({
          severity: "warn",
          summary: "Errore",
          detail: "Utente con questa combinazione di Codice Fiscale ed Email esiste già.",
          life: 10000,
        });
        return;
      }

      // Verifica esistenza utente con solo CF
      const checkCfResponse = await fetch(url_checkUser, {
        method: "POST",
        body: JSON.stringify({ codiceFiscale: verifyBody.fiscalCode }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (checkCfResponse.ok) {
        const checkCfData = await checkCfResponse.json();
        if (checkCfData.exists) {
          toast.current?.show({
            severity: "warn",
            summary: "Errore",
            detail: "Esiste già un utente con questo codice fiscale. Verifica l'email o il codice fiscale.",
            life: 10000,
          });
          return;
        }
      } else if (checkCfResponse.status === 400) {
        toast.current?.show({
          severity: "warn",
          summary: "Errore",
          detail: "Utente con questo Codice Fiscale esiste già.",
          life: 10000,
        });
        return;
      }

      // Verifica esistenza utente con solo email
      const checkEmailResponse = await fetch(url_checkUser, {
        method: "POST",
        body: JSON.stringify({ email: verifyBody.mail }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (checkEmailResponse.ok) {
        const checkEmailData = await checkEmailResponse.json();
        if (checkEmailData.exists) {
          toast.current?.show({
            severity: "warn",
            summary: "Errore",
            detail: "Esiste già un utente con questa email. Verifica l'email o contatta il centro.",
            life: 10000,
          });
          return;
        }
      } else if (checkEmailResponse.status === 400) {
        toast.current?.show({
          severity: "warn",
          summary: "Errore",
          detail: "Utente con questa Email esiste già.",
          life: 10000,
        });
        return;
      }

      // Verifica esistenza paziente con solo CF
      const verifyCfResponse = await fetch(url_checkPatient, {
        method: "POST",
        body: JSON.stringify({ code: verifyBody.fiscalCode }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (verifyCfResponse.ok) {
        const verifyCfData = await verifyCfResponse.json();
        if (verifyCfData.isPatientRegistered) {
          // Verifica esistenza paziente con solo email
          const verifyEmailResponse = await fetch(url_checkPatient, {
            method: "POST",
            body: JSON.stringify({ mail: verifyBody.mail }),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
          if (verifyEmailResponse.ok) {
            const verifyEmailData = await verifyEmailResponse.json();
            if (!verifyEmailData.isPatientRegistered) {
              // Aggiorna email del paziente
              const updateEmailResponse = await fetch(url_updateEmail, {
                method: "POST",
                body: JSON.stringify({ fiscalCode: verifyBody.fiscalCode, mail: verifyBody.mail }),
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              });
              const updateEmailData = await updateEmailResponse.json();

              if (updateEmailResponse.ok) {
                toast.current?.show({
                  severity: "success",
                  summary: "Email aggiornata",
                  detail: "L'email del paziente è stata aggiornata con successo.",
                  life: 10000,
                });
              } else {
                toast.current?.show({
                  severity: "error",
                  summary: "Errore",
                  detail: updateEmailData.errori,
                  life: 6000,
                });
              }
            } else {
              toast.current?.show({
                severity: "warn",
                summary: "Errore",
                detail: "Esiste già un paziente con questa email. Verifica i dati o contatta il centro.",
                life: 10000,
              });
              return;
            }
          } else {
            throw new Error("Errore nella verifica dell'email del paziente.");
          }
        }
      } else {
        throw new Error("Errore nella verifica del codice fiscale del paziente.");
      }

      // Verifica esistenza paziente con solo email
      const verifyEmailResponse = await fetch(url_checkPatient, {
        method: "POST",
        body: JSON.stringify({ mail: verifyBody.mail }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (verifyEmailResponse.ok) {
        const verifyEmailData = await verifyEmailResponse.json();
        if (!verifyEmailData.isPatientRegistered) {
          // Crea nuovo paziente
          const responsePatient = await fetch(url_patient, {
            method: "POST",
            body: JSON.stringify(patientBody),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
          if (responsePatient.ok) {
            const patientData = await responsePatient.json();
          } else {
            const patientData = await responsePatient.json();
            toast.current?.show({ severity: "error", summary: "Error", detail: patientData.errori, life: 6000 });
          }
        } else {
          toast.current?.show({
            severity: "warn",
            summary: "Errore",
            detail: "Esiste già un paziente con questa email. Verifica i dati o contatta il centro.",
            life: 10000,
          });
          return;
        }
      } else {
        throw new Error("Errore nella verifica dell'email del paziente.");
      }

      // Se tutto è ok, registra l'utente
      const response = await fetch(url_register, {
        method: "POST",
        body: JSON.stringify(bodyReg),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const responseData = await response.json();

      if (response.ok) {
        const responsePatient = await fetch(url_patient, {
          method: "POST",
          body: JSON.stringify(patientBody),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (responsePatient.ok) {
          const patientData = await responsePatient.json();
          toast.current?.show({
            severity: "success",
            summary: "Registrazione completata",
            detail: "Clicca il link ricevuto per email per validare l'account e poi fare il login.",
            life: 10000,
          });
        } else {
          const patientData = await responsePatient.json();
          toast.current?.show({ severity: "error", summary: "Error", detail: patientData.errori, life: 6000 });
        }
      } else {
        toast.current?.show({ severity: "error", summary: "Error", detail: responseData.errori, life: 6000 });
      }
    } catch (error) {
      console.error("An error occurred during registration:", error);
      toast.current?.show({
        severity: "error",
        summary: "Errore",
        detail: "C'è stato un errore, riprova tra un po' ",
        life: 3000,
      });
    }
  };

  const handleRegisterSubmit = () => {
    if (nome && cognome && email && birthdate && codiceFiscale && telefono && password && confirmPassword) {
      if (password !== confirmPassword) {
        toast.current?.show({
          severity: "error",
          summary: "Registrazione Fallita",
          detail: "Le password non coincidono.",
          life: 3000,
        });
        return;
      }

      // Verifica validità della password
      if (!validatePassword(password)) {
        setPasswordError("La password deve essere lunga almeno 8 caratteri, contenere lettere, numeri e un simbolo speciale.");
        return;
      }

      const regBody = {
        codiceFiscale: codiceFiscale,
        email: email,
        password: password,
      };
      const verifyBody = {
        mail: email,
        fiscalCode: codiceFiscale,
      };
      const patientBody = {
        firstName: nome,
        lastName: cognome,
        fiscalCode: codiceFiscale,
        gender: genere, // Può essere true, false, o null
        mail: email,
        birthDate: birthdate,
      };

      postRegister(regBody, patientBody, codiceFiscale, verifyBody);
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Registrazione Fallita",
        detail: "Per favore, compila tutti i campi.",
        life: 3000,
      });
    }
  };

  return (
    <div className="registration-div">
      <Toast ref={toast} position="center" />
      <div className="registration-form">
        <h2>Registrazione</h2>
        <div className="registration-input">
          <FloatLabel>
            <InputText
              id="cognome"
              value={cognome}
              onChange={e => setCognome(e.target.value)}
              className="input-field"
              required
            />
            <label>Cognome</label>
          </FloatLabel>
        </div>
        <div className="registration-input">
          <FloatLabel>
            <InputText
              id="nome"
              value={nome}
              onChange={e => setNome(e.target.value)}
              className="input-field"
              required
            />
            <label>Nome</label>
          </FloatLabel>
        </div>
        <div className="registration-input">
          <FloatLabel>
            <InputText
              id="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              className="input-field"
              required
            />
            <label>Email</label>
          </FloatLabel>
        </div>
        <div className="registration-input">
          <FloatLabel>
            <InputMask
              id="telefono"
              value={telefono}
              onChange={handleTelefonoChange}
              className="input-field"
              mask="9999999999"
              required
            />
            <label>Telefono</label>
          </FloatLabel>
        </div>
        <div className="registration-input">
          <FloatLabel>
            <InputMask
              id="codiceFiscale"
              value={codiceFiscale}
              onChange={handleCodiceFiscaleChange}
              className="input-field"
              mask="aaaaaa99a99a999a"
              required
            />
            <label>Codice Fiscale</label>
          </FloatLabel>
        </div>
        <div className="registration-input">
          <FloatLabel>
            <Calendar
              id="birthdate"
              value={birthdate}
              onChange={e => setBirthdate(e.value as Date)}
              showIcon
              dateFormat="dd/mm/yy"
              className="input-field"
              required
            />
            <label>Data di Nascita</label>
          </FloatLabel>
        </div>
        <div className="registration-input" style={{ width: "100%" }}>
          <label htmlFor="genere">Genere</label>
          <Dropdown
            id="genere"
            value={genere}
            options={generi}
            onChange={e => setGenere(e.value)}
            optionLabel="name"
            optionValue="code"
            placeholder="Seleziona un genere"
            className="input-field dropdown-gender"
            required
          />
        </div>
        <div className="registration-input password-container">
          <FloatLabel>
            <Password
              id="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              onBlur={handlePasswordBlur} /* Aggiunto onBlur per validare la password quando si lascia il campo */
              toggleMask
              feedback={false}
              className="input-field"
              required
            />
            <label>Password</label>
          </FloatLabel>
          {passwordError && <small className="p-error">{passwordError}</small>} {/* Messaggio di errore */}
        </div>
        <div className="registration-input password-container">
          <FloatLabel>
            <Password
              id="confirmPassword"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
              toggleMask
              feedback={false}
              className="input-field"
              required
            />
            <label>Conferma Password</label>
          </FloatLabel>
        </div>
        <div className="card flex flex-wrap justify-content-center gap-3">
          <Button className="submit-btn" label="Registrati" icon="pi pi-check" onClick={handleRegisterSubmit} />
        </div>
      </div>
    </div>
  );
};

export default Registration;
